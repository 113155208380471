@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  background-color: black;
  color: white;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0px; /* Width of the scrollbar */
}

@font-face {
  font-family: "MonumentExtended";
  src: url("/public/font/MonumentExtended-Regular.otf") format("opentype");
  font-display: swap;
}
@font-face {
  font-family: "MonumentExtendedUltrabold";
  src: url("/public/font/MonumentExtended-Ultrabold.otf") format("opentype");
  font-display: swap;
}
@font-face {
  font-family: "NeueMachinaregular";
  src: url("/public/font/NeueMachina-Regular.otf") format("opentype");
  font-display: swap;
}
@font-face {
  font-family: "NeueMachinabold";
  src: url("/public/font/NeueMachina-Ultrabold.otf") format("opentype");
  font-display: swap;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.hero {
  position: relative;
  /* background-image: url(../public/assets/images/herobg.png); */
  background-image: url(../public/assets/images/boybg2.png);
  height: 100%;
}

.hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.2
  ); /* Adjust the color and opacity as needed */
  z-index: 1;
}

/* Ensuring that content inside .hero is above the overlay */
.hero > * {
  position: relative;
  z-index: 2;
}
.herobtn {
  background: linear-gradient(
    to left,
    rgb(194, 194, 194, 25%),
    rgb(255, 255, 255, 25%)
  );
  backdrop-filter: blur(5px);
}
.clubbg {
  background-image: url(../public/assets/images/clubbg.png);
}
.gradient-btn {
  /* border-width: 1px;
  border-image: linear-gradient(
      to right,
      #6d6d69 0%,
      #4f532d 29%,
      #3e450c 46%,
      #1a1e04 100%
    )
    1; */

  /* background: linear-gradient(
    to right,
    rgb(43, 50, 9, 15%),
    rgb(162, 190, 11, 15%)
  ); */
  background-color: #cff600;
  color: black;
  border-radius: 20px;
}
.gradient-btn3 {
  border-width: 1px;
  border-image: linear-gradient(
      to right,
      #6d6d69 0%,
      #4f532d 29%,
      #3e450c 46%,
      #1a1e04 100%
    )
    1;
  background: #1a1e04;
}
.gradient-btn2 {
  background: linear-gradient(
    to right,
    rgb(255, 255, 255, 25%),
    rgb(241, 255, 168, 15%)
  );
  border-radius: 15px;
  backdrop-filter: blur(5px);
  color: white;
}
.gradient-btn5 {
  background: linear-gradient(
    to right,
    rgb(255, 255, 255, 25%),
    rgb(241, 255, 168, 15%)
  );
  border-radius: 30px;
  backdrop-filter: blur(5px);
  color: white;
}
.gradient-btn4 {
  background: linear-gradient(
    to right,
    rgb(255, 255, 255, 25%),
    rgb(241, 255, 168, 15%)
  );
  border-radius: 50px;
  /* backdrop-filter: blur(5px); */
  color: white;
}

.gradiant-bg {
  background: linear-gradient(
    to right,
    rgb(255, 255, 255, 15%),
    rgb(194, 194, 194, 15%)
  );
}
.gradiant-faq-box {
  background: linear-gradient(
    to right,
    rgb(255, 255, 255, 15%),
    rgb(194, 194, 194, 15%),
    rgb(50, 58, 5, 15%)
  );
}
.gradiant-club-box {
  background: linear-gradient(
    to left,
    rgb(194, 194, 194, 25%),
    rgb(255, 255, 255, 25%)
  );
  backdrop-filter: blur(5px);
}
.gradiant-roadmap-box {
  background: linear-gradient(
    to left,
    rgb(60, 64, 43, 45%),
    rgb(172, 204, 5, 45%)
  );
}
.gradiant-base-box {
  background: linear-gradient(
    to right,
    rgb(44, 100, 244, 65%),
    rgb(166, 197, 5, 25%)
  );
}
.EZDrawer__container {
  background-color: black !important;
}
.dropdowen-menu {
  backdrop-filter: blur(15px);
  background: linear-gradient(
    to left,
    rgb(194, 194, 194, 25%),
    rgb(255, 255, 255, 25%)
  );
  color: white;
}
